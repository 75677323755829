import React from "react";
import Map from "./Map";
import blogImage from "../images/img-dengue.webp";
const EventsDetailDengue = () => {
  return (
    <section className="event-detail-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blogImage} alt="" />
                  <span className="blog__tag">
                    <span className="date__num-text">9</span>
                    <span className="date__mon-text">mar</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="event-detail-content">
              <div className="event-detail-item">
                <h3 className="event__title">Dengue prevention camp</h3>
                <p className="event__text">
                  A dengue prevention camp was recently organized by an NGO
                  named “Purba Dhansara Jote Jana Kalyan Samity (NGO)”. The camp
                  aimed to raise awareness about dengue and how to prevent its
                  spread. The NGO distributed informational pamphlets and held
                  talks to educate people about the dangers of dengue and how to
                  protect themselves from it.
                </p>
                <p className="event__text">
                  They also demonstrated practical measures such as removing
                  standing water and using mosquito repellents to prevent the
                  spread of the disease. The camp was well-received by the
                  community and many people attended to learn more about dengue
                  prevention.
                </p>
              </div>
              <div className="event-detail-item">
                <h3 className="event__title event__title2">
                  Event Requirements
                </h3>
                <p className="event__text">
                  The NGO hopes that their efforts will help reduce the
                  incidence of dengue in the area and improve public health.
                  Dengue is a serious disease that can have severe consequences
                  if not treated promptly. By raising awareness and promoting
                  prevention measures, the NGO is making a positive impact on
                  the community.
                </p>
              </div>
              <div className="event-detail-btn">
                <a href="#none" className="theme-btn">
                  register now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="event-detail-sidebar">
              <div className="event-detail-item">
                <h3 className="event__title">Event Details</h3>
                <ul className="event__list">
                  <li>
                    <span>Starting Time:</span> 8:00AM to 2:00PM
                  </li>
                  <li>
                    <span>Date:</span>9 March, 2019
                  </li>
                  <li>
                    <span>Category:</span>Health
                  </li>
                  <li>
                    <span>Phone:</span>+91-9933086710
                  </li>
                  <li>
                    <span>Website:</span>info@pdjjks.org
                  </li>
                  <li>
                    <span>Location:</span>Siliguri, West Bengal, India
                  </li>
                </ul>
              </div>
              <div className="event-detail-item event-detail-item2">
                <Map />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsDetailDengue;
